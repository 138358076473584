export const monacoTheme = {
  "base": "vs-dark",
  "inherit": true,
  "rules": [
    {
      "background": "1E1E1E",
      "token": ""
    },
    {
      "foreground": "65737e",
      "token": "comment"
    },
    {
      "foreground": "65737e",
      "token": "punctuation.definition.comment"
    },
    {
      "foreground": "cdd3de",
      "token": "variable"
    },
    {
      "foreground": "c594c5",
      "token": "keyword"
    },
    {
      "foreground": "c594c5",
      "token": "storage.type"
    },
    {
      "foreground": "c594c5",
      "token": "storage.modifier"
    },
    {
      "foreground": "5fb3b3",
      "token": "keyword.operator"
    },
    {
      "foreground": "5fb3b3",
      "token": "constant.other.color"
    },
    {
      "foreground": "5fb3b3",
      "token": "punctuation"
    },
    {
      "foreground": "5fb3b3",
      "token": "meta.tag"
    },
    {
      "foreground": "5fb3b3",
      "token": "punctuation.definition.tag"
    },
    {
      "foreground": "5fb3b3",
      "token": "punctuation.separator.inheritance.php"
    },
    {
      "foreground": "5fb3b3",
      "token": "punctuation.definition.tag.html"
    },
    {
      "foreground": "5fb3b3",
      "token": "punctuation.definition.tag.begin.html"
    },
    {
      "foreground": "5fb3b3",
      "token": "punctuation.definition.tag.end.html"
    },
    {
      "foreground": "5fb3b3",
      "token": "punctuation.section.embedded"
    },
    {
      "foreground": "5fb3b3",
      "token": "keyword.other.template"
    },
    {
      "foreground": "5fb3b3",
      "token": "keyword.other.substitution"
    },
    {
      "foreground": "eb606b",
      "token": "entity.name.tag"
    },
    {
      "foreground": "eb606b",
      "token": "meta.tag.sgml"
    },
    {
      "foreground": "eb606b",
      "token": "markup.deleted.git_gutter"
    },
    {
      "foreground": "6699cc",
      "token": "entity.name.function"
    },
    {
      "foreground": "6699cc",
      "token": "meta.function-call"
    },
    {
      "foreground": "6699cc",
      "token": "variable.function"
    },
    {
      "foreground": "6699cc",
      "token": "support.function"
    },
    {
      "foreground": "6699cc",
      "token": "keyword.other.special-method"
    },
    {
      "foreground": "6699cc",
      "token": "meta.block-level"
    },
    {
      "foreground": "f2777a",
      "token": "support.other.variable"
    },
    {
      "foreground": "f2777a",
      "token": "string.other.link"
    },
    {
      "foreground": "f99157",
      "token": "constant.numeric"
    },
    {
      "foreground": "f99157",
      "token": "constant.language"
    },
    {
      "foreground": "f99157",
      "token": "support.constant"
    },
    {
      "foreground": "f99157",
      "token": "constant.character"
    },
    {
      "foreground": "f99157",
      "token": "variable.parameter"
    },
    {
      "foreground": "f99157",
      "token": "keyword.other.unit"
    },
    {
      "foreground": "99c794",
      "fontStyle": "normal",
      "token": "string"
    },
    {
      "foreground": "99c794",
      "fontStyle": "normal",
      "token": "constant.other.symbol"
    },
    {
      "foreground": "99c794",
      "fontStyle": "normal",
      "token": "constant.other.key"
    },
    {
      "foreground": "99c794",
      "fontStyle": "normal",
      "token": "entity.other.inherited-class"
    },
    {
      "foreground": "99c794",
      "fontStyle": "normal",
      "token": "markup.heading"
    },
    {
      "foreground": "99c794",
      "fontStyle": "normal",
      "token": "markup.inserted.git_gutter"
    },
    {
      "foreground": "99c794",
      "fontStyle": "normal",
      "token": "meta.group.braces.curly constant.other.object.key.js string.unquoted.label.js"
    },
    {
      "foreground": "fac863",
      "token": "entity.name.class"
    },
    {
      "foreground": "fac863",
      "token": "entity.name.type.class"
    },
    {
      "foreground": "fac863",
      "token": "support.type"
    },
    {
      "foreground": "fac863",
      "token": "support.class"
    },
    {
      "foreground": "fac863",
      "token": "support.orther.namespace.use.php"
    },
    {
      "foreground": "fac863",
      "token": "meta.use.php"
    },
    {
      "foreground": "fac863",
      "token": "support.other.namespace.php"
    },
    {
      "foreground": "fac863",
      "token": "markup.changed.git_gutter"
    },
    {
      "foreground": "ec5f67",
      "token": "entity.name.module.js"
    },
    {
      "foreground": "ec5f67",
      "token": "variable.import.parameter.js"
    },
    {
      "foreground": "ec5f67",
      "token": "variable.other.class.js"
    },
    {
      "foreground": "ec5f67",
      "fontStyle": "italic",
      "token": "variable.language"
    },
    {
      "foreground": "cdd3de",
      "token": "meta.group.braces.curly.js constant.other.object.key.js string.unquoted.label.js"
    },
    {
      "foreground": "d8dee9",
      "token": "meta.class-method.js entity.name.function.js"
    },
    {
      "foreground": "d8dee9",
      "token": "variable.function.constructor"
    },
    {
      "foreground": "d8dee9",
      "token": "meta.class.js meta.class.property.js meta.method.js string.unquoted.js entity.name.function.js"
    },
    {
      "foreground": "bb80b3",
      "token": "entity.other.attribute-name"
    },
    {
      "foreground": "99c794",
      "token": "markup.inserted"
    },
    {
      "foreground": "ec5f67",
      "token": "markup.deleted"
    },
    {
      "foreground": "bb80b3",
      "token": "markup.changed"
    },
    {
      "foreground": "5fb3b3",
      "token": "string.regexp"
    },
    {
      "foreground": "5fb3b3",
      "token": "constant.character.escape"
    },
    {
      "fontStyle": "underline",
      "token": "*url*"
    },
    {
      "fontStyle": "underline",
      "token": "*link*"
    },
    {
      "fontStyle": "underline",
      "token": "*uri*"
    },
    {
      "foreground": "ab7967",
      "token": "constant.numeric.line-number.find-in-files - match"
    },
    {
      "foreground": "99c794",
      "token": "entity.name.filename.find-in-files"
    },
    {
      "foreground": "6699cc",
      "fontStyle": "italic",
      "token": "tag.decorator.js entity.name.tag.js"
    },
    {
      "foreground": "6699cc",
      "fontStyle": "italic",
      "token": "tag.decorator.js punctuation.definition.tag.js"
    },
    {
      "foreground": "ec5f67",
      "fontStyle": "italic",
      "token": "source.js constant.other.object.key.js string.unquoted.label.js"
    },
    {
      "foreground": "fac863",
      "token": "source.json meta meta meta meta meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json string.quoted.double.json - meta meta meta meta meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "fac863",
      "token": "source.json meta meta meta meta meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json punctuation.definition.string - meta meta meta meta meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    },
    {
      "foreground": "c594c5",
      "token": "source.json meta meta meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json string.quoted.double.json - meta meta meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "c594c5",
      "token": "source.json meta meta meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json punctuation.definition.string - meta meta meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    },
    {
      "foreground": "d8dee9",
      "token": "source.json meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json string.quoted.double.json - meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "d8dee9",
      "token": "source.json meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json punctuation.definition.string - meta meta meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    },
    {
      "foreground": "6699cc",
      "token": "source.json meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json string.quoted.double.json - meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "6699cc",
      "token": "source.json meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json punctuation.definition.string - meta meta meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    },
    {
      "foreground": "ab7967",
      "token": "source.json meta meta meta meta meta meta meta meta.structure.dictionary.json string.quoted.double.json - meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "ab7967",
      "token": "source.json meta meta meta meta meta meta meta meta.structure.dictionary.json punctuation.definition.string - meta meta meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    },
    {
      "foreground": "ec5f67",
      "token": "source.json meta meta meta meta meta meta.structure.dictionary.json string.quoted.double.json - meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "ec5f67",
      "token": "source.json meta meta meta meta meta meta.structure.dictionary.json punctuation.definition.string - meta meta meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    },
    {
      "foreground": "f99157",
      "token": "source.json meta meta meta meta.structure.dictionary.json string.quoted.double.json - meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "f99157",
      "token": "source.json meta meta meta meta.structure.dictionary.json punctuation.definition.string - meta meta meta meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    },
    {
      "foreground": "fac863",
      "token": "source.json meta meta.structure.dictionary.json string.quoted.double.json - meta meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "fac863",
      "token": "source.json meta meta.structure.dictionary.json punctuation.definition.string - meta meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    },
    {
      "foreground": "c594c5",
      "token": "source.json meta.structure.dictionary.json string.quoted.double.json - meta.structure.dictionary.json meta.structure.dictionary.value.json string.quoted.double.json"
    },
    {
      "foreground": "c594c5",
      "token": "source.json meta.structure.dictionary.json punctuation.definition.string - meta.structure.dictionary.json meta.structure.dictionary.value.json punctuation.definition.string"
    }
  ],
  "colors": {
    "editor.foreground": "#CDD3DE",
    "editor.background": "#0D1111",
    "editor.selectionBackground": "#4f5b66",
    "editor.lineHighlightBackground": "#1E1E1E",
    "editorCursor.foreground": "#c0c5ce",
    "editorWhitespace.foreground": "#65737e",
    "editorIndentGuide.background": "#65737F",
    "editorIndentGuide.activeBackground": "#FBC95A"

  }
}