

export const INTERVIEW_RETRIEVE_REQUEST = 'INTERVIEW_RETRIEVE_REQUEST'
export const INTERVIEW_RETRIEVE_SUCCESS = 'INTERVIEW_RETRIEVE_SUCCESS'
export const INTERVIEW_RETRIEVE_FAILED = 'INTERVIEW_RETRIEVE_FAILED'
export const INTERVIEW_RETRIEVE_RESET = 'INTERVIEW_RETRIEVE_RESET'


export const INTERVIEW_CONTINUE_REQUEST = 'INTERVIEW_CONTINUE_REQUEST'
export const INTERVIEW_CONTINUE_SUCCESS = 'INTERVIEW_CONTINUE_SUCCESS'
export const INTERVIEW_CONTINUE_FAILED = 'INTERVIEW_CONTINUE_FAILED'
export const INTERVIEW_CONTINUE_RESET = 'INTERVIEW_CONTINUE_RESET'


export const INTERVIEW_UPDATE_REQUEST = 'INTERVIEW_UPDATE_REQUEST'
export const INTERVIEW_UPDATE_SUCCESS = 'INTERVIEW_UPDATE_SUCCESS'
export const INTERVIEW_UPDATE_FAILED = 'INTERVIEW_UPDATE_FAILED'
export const INTERVIEW_UPDATE_RESET = 'INTERVIEW_UPDATE_RESET'

export const INTERVIEW_SCREEN_UPDATE = 'INTERVIEW_SCREEN_SUCCESS'