import React, {useEffect, useState} from 'react';
import {useAudioRecorder} from "../commons/hooks";
import Loader from "./Loader";
import {RECORDER_STATUS} from "../constants/enums";
import {useDispatch, useSelector} from "react-redux";
import {liveTranscribe, speak, transcribe} from "../store/linguistic/linguisticActions";
import {settings} from "../commons/config";
import {UTILS} from "../commons/utils";
import BgGradientText from "./BgGradientText";
import BgGradientButton from "./BgGradientButton";

const mimeType = 'audio/wav';

function TestAudioTranscriber(props) {
  const dispatch = useDispatch();

  const liveTranscribeAudio = useSelector(state => state.liveTranscribedAudio)
  const { transcript } = liveTranscribeAudio;

  // const [text, setText] = useState("");

  let transcriptConfig = {
    chunkSize: 2000,
    chunkHandler: (chunk, allChunks) => {
      if(allChunks[0] !== chunk) { // Get the headers from the first chunk, without headers the wav chunk is invalid.
        let first = allChunks[0];
        chunk = new Blob([first.slice(0, 44), chunk])
      }

      const audioFile = new File([chunk], 'test.wav', { type: mimeType });
      console.log("lets go to havannah!")
      if(settings.liveTranscript.websocket) {
        UTILS.fileToBase64(audioFile).then(data => {
          console.log(data.length)
          dispatch({
            type: 'socket/send',
            payload: data
          })
        })
      }
      else {
        const data = new FormData();
        data.append('id', 'interview');
        data.append('condition_on_previous_text', false);
        data.append('prefix', transcript ? transcript.text : "");
        // data.append('beam_size', 3);
        data.append('file', audioFile);

        dispatch(liveTranscribe(data));
      }
    }
  }

  let {
    permissions,
    recorderStatus,
    audio,
    startRecording,
    stopRecording
  } = useAudioRecorder( {
    chunkSize: settings.liveTranscript.enabled ? transcriptConfig.chunkSize : null,
    chunkHandler: settings.liveTranscript.enabled ? transcriptConfig.chunkHandler : null
  });

  // console.log('recording status -> ', recorderStatus);


  useEffect(() => {
    if(audio) {
      // let data = new FormData();
      // data.append('condition_on_previous_text', true);
      // data.append('file', new File([audio], `full-${Date.now()}.wav`, { type: mimeType }));
      // data.append('response_format', 'json');
      //
      // dispatch(transcribe(data));
      console.log('Recording complete...');
    }
  }, [audio]);

  useEffect(() => {
    if(transcript && transcript.text && (transcript.text.includes('[BLANK_AUDIO]') || transcript.text.trim() === "" || transcript.text.trim().length === 0)) {
      // console.log('calling stop recording from live transcript change')
      // alert("stopping recoroding")
      stopRecording();
      props.onStopHandler();
    }
  }, [transcript]);

  useEffect(() => {
    if(props.listen) {
      startRecording();
    }
  }, [props.listen]);


  return (
    <div className='flex flex-col w-full'>
      {
        !permissions ? (
          <button className='flex flex-row bg-!grey-400/30 hover:bg-!grey-400/50 rounded-md py-2 px-4'>
            <Loader />
          </button>
        ) : recorderStatus === RECORDER_STATUS.INACTIVE ? (
          <button className='flex flex-row bg-!grey-400/30 hover:bg-!grey-400/50 rounded-md py-2 px-4 gap-x-2'
                  disabled={props.disabled}
                  onClick={() => {
                    dispatch({
                      type: 'socket/connect'
                    })
                    startRecording();
                  }}
          >
            <span className='flex flex-col m-auto'>
              <img className='h-[1rem] m-auto' src='/media/images/icons/mic.webp' alt='' />
            </span>
            <span className='flex flex-col'>Start</span>
          </button>
        ) : (
          <button className='flex flex-row bg-!grey-400/30 hover:bg-!grey-400/50 rounded-md py-2 px-4 gap-x-2'
                  disabled={props.disabled}
                  onClick={() => {
                    dispatch({
                      type: 'socket/disconnect'
                    })
                    stopRecording();
                  }}
          >
            <span className='flex flex-col m-auto'>
              <img className='h-[1rem] m-auto border-[2px] border-!red-600 rounded-full' src='/media/images/icons/stop-2.webp' alt='' />
            </span>
            <span className='flex flex-col'>Stop</span>
          </button>
        )
      }

      <div>{transcript && transcript.text}</div>

      <div>
        <button className='flex flex-row bg-!grey-400/30 hover:bg-!grey-400/50 rounded-md py-2 px-4 gap-x-2' onClick={() => {
          dispatch(speak({
            'id': "test",
            'text': "this is test",
          }))
        }}>audio</button>
      </div>
    </div>
  );
}

export default TestAudioTranscriber;