import React from 'react';
import AudioRecorder from "./components/AudioRecorder";
import Layout from "./layout/Layout";
import VideoRecorder from "./components/VideoRecorder";
import AudioTranscriber from "./components/AudioTranscriber";
import GradientButton from "./components/GradientButton";
import TestAudioTranscriber from "./components/TestAudioTranscriber";

function Test(props) {
  return (
    <Layout>
      <div className='flex flex-row w-[50%] m-auto '>
        <TestAudioTranscriber />
        {/*<AudioRecorder />*/}

        {/*<VideoRecorder />*/}
      </div>
    </Layout>
  );
}

export default Test;