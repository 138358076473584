export const RECORDER_STATUS = {
  INITIALIZING: 'initializing',
  INACTIVE: 'inactive',
  RECORDING: 'recording',
  PAUSED: 'paused'
}

export const SPEECH_STATUS = {
  INACTIVE: 'inactive',
  ACTIVE: 'active',
  SPEAKING: 'speaking',
  PAUSED: 'paused',
  ENDED: 'ended',
}

export const MESSAGE_TYPE = {
  AI: 'ai',
  USER: 'user'
}

export const USER_TYPE = {
  AI: 'ai',
  USER: 'user'
}

export const STAGE_TYPE = {
  BASE: 'BASE',
  DSA: 'DSA'
}

export const MEETINGS_STATUS = {
  PRE_MEETING: 'PRE_MEETING',
  MEETING: 'MEETING',
  POST_MEETING: 'POST_MEETING'
}

export const INTERVIEW_STATUS = {
  SCHEDULED: 'scheduled',
  IN_PROGRESS: 'in_progress',
  COMPLETED: 'completed',
  INCOMPLETE: 'incomplete',
  CANCELLED: 'cancelled',
  TERMINATED: 'terminated',
  ERROR: 'error',
}
