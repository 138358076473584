export const TRANSCRIBE_REQUEST = 'TRANSCRIBE_REQUEST'
export const TRANSCRIBE_SUCCESS = 'TRANSCRIBE_SUCCESS'
export const TRANSCRIBE_FAILED = 'TRANSCRIBE_FAILED'
export const TRANSCRIBE_RESET = 'TRANSCRIBE_RESET'

export const TRANSCRIBE_WARMUP_REQUEST = 'TRANSCRIBE_WARMUP_REQUEST'
export const TRANSCRIBE_WARMUP_SUCCESS = 'TRANSCRIBE_WARMUP_SUCCESS'
export const TRANSCRIBE_WARMUP_FAILED = 'TRANSCRIBE_WARMUP_FAILED'
export const TRANSCRIBE_WARMUP_RESET = 'TRANSCRIBE_WARMUP_RESET'

export const LIVE_TRANSCRIBE_REQUEST = 'LIVE_TRANSCRIBE_REQUEST'
export const LIVE_TRANSCRIBE_SUCCESS = 'LIVE_TRANSCRIBE_SUCCESS'
export const LIVE_TRANSCRIBE_FAILED = 'LIVE_TRANSCRIBE_FAILED'
export const LIVE_TRANSCRIBE_RESET = 'LIVE_TRANSCRIBE_RESET'

export const SPEAK_REQUEST = 'SPEAK_REQUEST'
export const SPEAK_SUCCESS = 'SPEAK_SUCCESS'
export const SPEAK_FAILED = 'SPEAK_FAILED'
export const SPEAK_RESET = 'SPEAK_RESET'