export const AUDIO_REQUEST = 'AUDIO_REQUEST';
export const AUDIO_SUCCESS = 'AUDIO_SUCCESS';
export const AUDIO_FAILED = 'AUDIO_FAILED';
export const AUDIO_RESET = 'AUDIO_RESET';

export const VIDEO_REQUEST = 'VIDEO_REQUEST';
export const VIDEO_SUCCESS = 'VIDEO_SUCCESS';
export const VIDEO_FAILED = 'VIDEO_FAILED';
export const VIDEO_RESET = 'VIDEO_RESET';

export const AV_PERMISSION_REQUEST = 'AV_PERMISSION_REQUEST';
export const AV_PERMISSION_SUCCESS = 'AV_PERMISSION_SUCCESS';
export const AV_PERMISSION_FAILED = 'AV_PERMISSION_FAILED';
export const AV_PERMISSION_RESET = 'AV_PERMISSION_RESET';